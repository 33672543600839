@font-face {
  font-family: "Neue Plak";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/NeuePlakRegular.ttf");
}

@font-face {
  font-family: "Neue Plak";
  font-style: normal;
  font-weight: 600;
  src: url("../fonts/NeuePlakSemiBold.ttf");
}

@font-face {
  font-family: "Neue Plak";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/NeuePlakTextBold.ttf");
}
