:root {
  --font-family: "Neue Plak";
  --w: #fcfcfc;
  --b: #141718;
}

h1 {
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: 56px; /* 116.667% */
}

h2 {
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 48px; /* 120% */
}

h3 {
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px; /* 114.286% */
}

h4 {
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px; /* 133.333% */
}

h5 {
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 120% */
}

.p1 {
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 120% */
}

.p2 {
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 120% */
}

.p3 {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}
.btn1 {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 100% */
}
.btn2 {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 14px */
}

body {
  height: 100dvh;
  width: 100%;
  background-color: var(--w);
  font-family: var(--font-family);
}
@media screen and (max-width: 1513px) {
  h1 {
    font-family: Neue Plak;
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
  }

  h2 {
    font-family: Neue Plak;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px;
  }

  h3 {
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px; /* 114.286% */
  }

  h4 {
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px; /* 133.333% */
  }

  h5 {
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 120% */
  }

  .p1 {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }

  .p2 {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }

  .p3 {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
}
@media screen and (max-width: 768px) {
  h1 {
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px;
  }

  h2 {
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
  }

  h3 {
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
  }

  h4 {
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
  }

  h5 {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
  }

  .p1 {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }

  .p2 {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  .p3 {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }
}
body > #root {
  overflow: hidden;
  height: 100%;
  width: 100%;
}

.aic {
  display: flex;
  justify-content: center;
  align-items: center;
}
.debug {
  border: 1px dashed red;
}
.anim {
  transition: all 300ms ease-in;
}

.anim2 {
  transition: all 0.3s ease-in-out;
}

.hover:hover {
  cursor: pointer;
}

.scale10:hover {
  transform: scale(1.01);
}
.underline {
  background: linear-gradient(0deg, currentColor, currentColor) no-repeat right
    bottom / 0 2px;
  transition: background-size 350ms;
  padding-bottom: 2px;
}
.underline:where(:hover, :focus-visible) {
  background-size: 100% 2px;
  background-position-x: left;
}

*::-webkit-scrollbar {
  display: none;
  /* cursor: pointer;
  width: 4px;
  height: 4px;
  box-sizing: border-box; */
}

*::-webkit-scrollbar-track {
  /* border-radius: 3px;
  border: 1px solid transparent;
  background: transparent;
  backdrop-filter: blur(4px); */
  display: none;
}

/* *::-webkit-scrollbar-thumb {
  background: #a292a4;
  width: 4px;
  height: 4px;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
} */

.shake-bell {
  animation-name: bell-animation;
  animation-duration: 2.5s;
  animation-iteration-count: infinite;
  display: inline-block;
}

@keyframes bell-animation {
  5% {
    transform: rotate(10deg);
  }

  10% {
    transform: rotate(-10deg);
  }

  15% {
    transform: rotate(10deg);
  }

  20% {
    transform: rotate(0deg);
  }
}

.wave {
  animation-name: wave-animation;
  animation-duration: 2.5s;
  animation-iteration-count: infinite;
  transform-origin: 70% 70%;
  display: inline-block;
}

@keyframes wave-animation {
  0% {
    transform: rotate(0deg);
  }

  10% {
    transform: rotate(14deg);
  }

  20% {
    transform: rotate(-8deg);
  }

  30% {
    transform: rotate(14deg);
  }

  40% {
    transform: rotate(-4deg);
  }

  50% {
    transform: rotate(10deg);
  }

  60% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

.tooltip::before {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  padding: 0.5rem 0.75rem;
  content: attr(data-tooltip);
  transform: translateY(-35px);
  border-radius: 8px;
  background-color: var(--b);
  color: var(--w);
  transition: 0.3s ease-in-out;
  text-align: center;

  opacity: 0;
  scale: 0;
  visibility: hidden;
  z-index: -1;
}

.tooltip:hover::before {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  padding: 0.5rem 0.75rem;
  content: attr(data-tooltip);
  transform: translateY(-35px);
  border-radius: 8px;
  background-color: var(--b);
  color: var(--w);
  transition: 0.3s ease-in-out;
  text-align: center;

  scale: 1;
  opacity: 1;
  visibility: visible;
  z-index: 1;
}

.tooltip[data-tooltip-bottom="true"]::before,
.tooltip[data-tooltip-bottom="true"]:hover::before {
  transform: translateY(35px) !important;
}

.unselectable {
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.rotate {
  animation-name: rotate;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.star-rotate {
  animation-name: rotate;
  animation-duration: 1s;
  animation-timing-function: cubic-bezier(0.54, 0.21, 0.18, 1.35);
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.fromleft {
  animation-name: tranlateleft;
  animation-duration: 0.2s;
  animation-timing-function: ease-in-out;
}

@keyframes tranlateleft {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0%);
  }
}

.animhover {
  animation-play-state: paused;
}

.animhover:hover {
  animation-play-state: running;
}

.message-success {
  color: #42bf1e;
  font-weight: bold;
}

.message-error {
  color: #e43f67;
  font-weight: bold;
}
@keyframes img-position {
  0%,
  25%,
  75% {
    object-position: top;
  }
  10%,
  35%,
  55% {
    object-position: bottom;
  }
  100% {
    transform: scale(1);
    object-position: center;
  }
}
::view-transition-group(root) {
  animation-duration: 1.2s;
  animation-timing-function: cubic-bezier(0.54, 0.21, 0.18, 1.35);
}
::view-transition-new(root),
::view-transition-old(root) {
  mix-blend-mode: normal;
}

::view-transition-new(root) {
  animation-name: reveal-left;
}

::view-transition-old(root),
.left::view-transition-old(root) {
  animation: none;
}
.left::view-transition-new(root) {
  animation-name: reveal-right;
}

@keyframes reveal-right {
  from {
    clip-path: polygon(-30% 0, -30% 0, -15% 100%, -10% 115%);
  }
  to {
    clip-path: polygon(-30% 0, 130% 0, 115% 100%, -10% 115%);
  }
}

@keyframes reveal-left {
  from {
    clip-path: polygon(130% 0, 130% 0, 115% 100%, 110% 115%);
  }
  to {
    clip-path: polygon(130% 0, -30% 0, -15% 100%, 110% 115%);
  }
}
